import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
var swiper = new Swiper('.index-banner .swiper', {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    speed:1000,
    pagination: {
        el: ".swiper-pagination",
    },
    loop:true,//必须
});
$("header").removeClass("head1");
$(function () {
  $(window).scroll(function () {
      // 可是窗口滚动条发生滚动的时候 垂直方向滚动到多少距离的时候 让头部导航条设置上一个固定定位的样式
      if ($(window).scrollTop() >= ($("header").height() - 50)) {
          // alert($("#slideBox").height());
          $("header").addClass("head1");
      } else {
          $("header").removeClass("head1");
      }
  })
})
